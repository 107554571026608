import { FormikWizard } from "formik-wizard-form";
import Notiflix from "notiflix";
import { useEffect } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import { createPatient } from "../../../apis/patientApi";
import { useGetSeraquickPatientInfo } from "../../../apis/seraquickApi";
import { meditScan } from "../../../atoms/meditAtom";
import { userInfo } from "../../../atoms/userAtom";
import { CardTail } from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { IK } from "../../../utils/i18n/keyStore";
import { convertToY, deleteEmptyKey } from "../../../utils/objectUtils";
import ClinicalCondition from "./ClinicalCondition/ClinicalCondition";
import Extraoral from "./Extraoral/Extraoral";
import Intraoral from "./Intraoral/Intraoral";
import Radiation from "./Radiation/Radiation";
import RegisterForm from "./RegisterForm/RegisterForm";
import Scan from "./Scan/Scan";
import Steps from "./Steps/Steps";

function Register() {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  //세라퀵 아이디 조회
  const { state } = useLocation();

  const { data: seraquickData } = useGetSeraquickPatientInfo(state?.seraquickId);

  /**사용자 정보 가져오기 */
  const { countryCode } = useRecoilValue(userInfo);

  /**메딧 가져오기 */
  const [meditFiles, setMeditFiles] = useRecoilState(meditScan);

  useEffect(() => {
    // 컴포넌트 마운트 시 meditFiles 초기화
    if (meditFiles.length > 0) {
      setMeditFiles([]);
    }

    // 컴포넌트 언마운트 시 cleanup
    return () => {
      if (meditFiles.length > 0) {
        setMeditFiles([]);
      }
    };

    // eslint-disable-next-line
  }, []);

  /**이미지 유효성 검사 */
  const handleImageRequired = (fieldName, errorMessage, getFieldProps, setFieldError) => {
    if (!getFieldProps(fieldName).value || getFieldProps(fieldName).value.length === 0) {
      setFieldError(fieldName, t(errorMessage));
      return false;
    }
    return true;
  };

  /**뒤로가기 방지 */
  const handleOnClickFirstPrev = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Notiflix.Confirm.show(t(IK.warning_prev_1), t(IK.warning_prev_2), t(IK.confirm), t(IK.cancel), () => navigate("/patient/list"));
  };

  /**환자 등록 제출 */
  const handlePatientSubmit = (values) => {
    Notiflix.Loading.standard("");
    const data = new FormData();
    Object.keys(deleteEmptyKey(values)).forEach((key) => {
      const value = values[key];

      if (key === "scan" && Array.isArray(value)) {
        value.forEach((file) => {
          if (file instanceof File) data.append("scan", file);
        });
      } else if (key === "conditionItemMap") {
        data.append(key, JSON.stringify(convertToY(values[key])));
      } else {
        data.append(key, value);
      }
    });

    //메디파일이 있으면 추가
    if (meditFiles.length > 0) {
      data.append("meditFiles", JSON.stringify(meditFiles));
    }

    //세라퀵 아이디가 있으면 추가
    if (state?.seraquickId) data.append("seraQuickId", state?.seraquickId);

    createPatient(data)
      .then((response) => {
        navigate("/patient/" + response.data.data + "/confirm", {
          state: { isOk: true },
        });
        setMeditFiles([]);
      })
      .catch((res) => {
        //파일 등록 오류시 에러
        const message = res?.response?.data?.message === "file_faile" ? [t(IK.file_faile), t(IK.file_faile_check)] : [t(IK.patient_registration_fail), t(IK.again_message)];

        Notiflix.Report.failure(...message, t(IK.confirm));
      })
      .finally(() => {
        Notiflix.Loading.remove();
      });
  };

  return (
    <>
      <Beforeunload onBeforeunload={() => ""}>
        <FormikWizard
          initialValues={{
            korName: seraquickData?.patientInfo?.korName || "",
            lastName: seraquickData?.patientInfo?.lastName || "",
            firstName: seraquickData?.patientInfo?.firstName || "",
            gender: seraquickData?.patientInfo?.gender || "",
            birthday: seraquickData?.patientInfo?.birthday || "",
            race: "",
            shipAddressName: "",
            shipAddress: "",
            shipPostalCode: "",
            shipAddressDetail: "",
            billAddressName: "",
            billAddress: "",
            billPostalCode: "",
            billAddressDetail: "",
            conditionItemMap: {
              class1: false,
              class2: false,
              class3: false,
              class4: false,
              crowding: false,
              spacing: false,
              narrowArch: false,
              openbiteVerticality: false,
              unevenSmile: false,
              occlusalPlaneRight: false,
              deepBite: false,
              openbiteOverjet: false,
              occlusalPlaneLeft: false,
              abteriorCrossbite: false,
              misshapenTeeth: false,
              posteriorCrossbite: false,
              missing: false,
              flaredTeeth: false,
              asymmetric: false,
              gingivalRecession: false,
              scissorsBite: false,
              other: false,
              otherText: "",
            },
            extraoral_front: "",
            front_rest: "",
            front_smile: "",
            side45: "",
            side90: "",
            side90_smile: "",
            occlusal_upper: "",
            intraoral_front: "",
            occlusal_lower: "",
            buccal_right: "",
            buccal_left: "",
            panorama: "",
            cephalo: "",
            scan: seraquickData?.seraQuickFiles?.length > 0 ? seraquickData.seraQuickFiles : [],
            seraQuickDeleteFileIds: [],
          }}
          onSubmit={handlePatientSubmit}
          validateOnNext={true}
          activeStepIndex={0}
          steps={[
            {
              component: RegisterForm,
              validationSchema: Yup.object().shape({
                korName:
                  countryCode === "01"
                    ? Yup.string()
                        .matches(/^[가-힣0-9]+$/, t(IK.only_korean_number))
                        .required(t(IK.korea_name_required))
                        .max(10, t(IK.name_length_limit_10))
                    : Yup.string(),
                lastName: Yup.string()
                  .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
                  .required(t(IK.english_name_required))
                  .max(20, t(IK.name_length_limit)),
                firstName: Yup.string()
                  .matches(/^[a-zA-Z]+$/, t(IK.only_engilsh))
                  .required(t(IK.english_name_required))
                  .max(20, t(IK.name_length_limit)),
                gender: Yup.string().required(t(IK.gender_required)),
                birthday: Yup.string()
                  .required(t(IK.birthday_required))
                  .matches(/^\d{4}-\d{2}-\d{2}$/, t(IK.date_format_valid)),
                race: Yup.string().required(t(IK.race_required)),
                shipAddressName: Yup.string().required(t(IK.clinic_hospital_required)),
                shipPostalCode: Yup.string().required(t(IK.postcode_required)),
                shipAddress: Yup.string().required(t(IK.address_required)),
                billAddressName: Yup.string().required(t(IK.clinic_hospital_required)),
                billPostalCode: Yup.string().required(t(IK.postcode_required)),
                billAddress: Yup.string().required(t(IK.address_required)),
              }),
            },
            {
              component: ClinicalCondition,
              validationSchema: Yup.object().shape({
                conditionItemMap: Yup.object()
                  .test("has-true-value", t(IK.check_one_over), function (value) {
                    const hasTrueValue = Object.keys(value).some((key) => value[key] === true);
                    return hasTrueValue;
                  })
                  .test("has-other-text", t(IK.othertext_required), function (value) {
                    if (value.other && !value.otherText) {
                      return false; // 오류 반환
                    }
                    return true; // 유효성 통과
                  })
                  .test("has-other-text-length", t(IK.otherText_length_limit), function (value) {
                    if (value.otherText?.length > 80) {
                      return false;
                    }
                    return true;
                  }),
              }),
            },
            {
              id: "extraoral",
              component: Extraoral,
            },
            {
              id: "intraoral",
              component: Intraoral,
            },
            {
              id: "radiation",
              component: Radiation,
            },
            {
              id: "scan",
              component: Scan,
            },
          ]}
        >
          {({ renderComponent, handlePrev, handleNext, isLastStep, isFirstStep, currentStepIndex, setTouched, validateForm, setFieldError, setFieldValue, getFieldProps, errors }) => (
            <S2.CardRow>
              <S2.CardCol>
                <Steps currentStepIndex={currentStepIndex} />
                <S2.CardInner>
                  {renderComponent()}
                  {/* 환자 정보 입력 */}
                  {isFirstStep && (
                    <CardTail line>
                      <S.ButtonLtBox>
                        <S.StyledButton as="button" onClick={handleOnClickFirstPrev}>
                          {t(IK.cancel)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() =>
                            validateForm().then((errors) => {
                              if (Object.entries(errors).length === 0 && errors.constructor === Object) {
                                handleNext();
                              } else {
                                setTouched(errors);
                                console.log(errors);
                              }
                            })
                          }
                        >
                          {t(IK.next)}
                        </S.StyledButton>
                      </S.ButtonLtBox>
                    </CardTail>
                  )}

                  {/* 임상상태 */}
                  {currentStepIndex === 1 && (
                    <CardTail error={errors.conditionItemMap} line>
                      <S.ButtonLtBox>
                        <S.StyledButton as="button" type="button" onClick={handlePrev}>
                          {t(IK.prev)}
                        </S.StyledButton>
                        <S.StyledButton as="button" $primary type="button" onClick={handleNext}>
                          {t(IK.next)}
                        </S.StyledButton>
                      </S.ButtonLtBox>
                    </CardTail>
                  )}

                  {/* EXTRAORAL */}
                  {currentStepIndex === 2 && (
                    <CardTail error={t(IK.image_required_message_2)} line>
                      <S.ButtonLtBox>
                        <S.StyledButton as="button" type="button" onClick={handlePrev}>
                          {t(IK.prev)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            setFieldValue("extraoral_front", "");
                            setFieldValue("front_rest", "");
                            setFieldValue("front_smile", "");
                            setFieldValue("side45", "");
                            setFieldValue("side90", "");
                            setFieldValue("photo_side90_smile", "");
                            handleNext();
                          }}
                        >
                          {t(IK.upload_photos_later)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            const isValid =
                              handleImageRequired("extraoral_front", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("front_smile", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("side90", IK.image_required_message_1, getFieldProps, setFieldError);

                            if (isValid) {
                              handleNext();
                            }
                          }}
                        >
                          {t(IK.next)}
                        </S.StyledButton>
                      </S.ButtonLtBox>
                    </CardTail>
                  )}

                  {/* INTRAORAL */}
                  {currentStepIndex === 3 && (
                    <CardTail error={t(IK.image_required_message_2)} line>
                      <S.ButtonLtBox>
                        <S.StyledButton as="button" type="button" onClick={handlePrev}>
                          {t(IK.prev)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            setFieldValue("occlusal_upper", "");
                            setFieldValue("occlusal_lower", "");
                            setFieldValue("intraoral_front", "");
                            setFieldValue("buccal_right", "");
                            setFieldValue("overjet", "");
                            setFieldValue("buccal_left", "");
                            handleNext();
                          }}
                        >
                          {t(IK.upload_photos_later)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            const isValid =
                              handleImageRequired("occlusal_upper", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("occlusal_lower", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("buccal_right", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("overjet", IK.image_required_message_1, getFieldProps, setFieldError) &&
                              handleImageRequired("buccal_left", IK.image_required_message_1, getFieldProps, setFieldError);
                            if (isValid) {
                              handleNext();
                            }
                          }}
                        >
                          {t(IK.next)}
                        </S.StyledButton>
                      </S.ButtonLtBox>
                    </CardTail>
                  )}

                  {/* 방사선 사진 */}
                  {currentStepIndex === 4 && (
                    <CardTail error={t(IK.image_required_message_2)} line>
                      <S.ButtonLtBox>
                        <S.StyledButton as="button" type="button" onClick={handlePrev}>
                          {t(IK.prev)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            setFieldValue("panorama", "");
                            setFieldValue("cephalo", "");
                            handleNext();
                          }}
                        >
                          {t(IK.upload_photos_later)}
                        </S.StyledButton>
                        <S.StyledButton
                          as="button"
                          $primary
                          type="button"
                          onClick={() => {
                            const isValid = handleImageRequired("panorama", IK.image_required_message_1, getFieldProps, setFieldError);

                            if (isValid) {
                              handleNext();
                            }
                          }}
                        >
                          {t(IK.next)}
                        </S.StyledButton>
                      </S.ButtonLtBox>
                    </CardTail>
                  )}

                  {/* 스캔파일 등록 */}
                  {isLastStep && (
                    <>
                      <CardTail line>
                        <S.ButtonLtBox>
                          <S.StyledButton as="button" type="button" onClick={handlePrev}>
                            {t(IK.prev)}
                          </S.StyledButton>
                          <S.StyledButton
                            as="button"
                            $primary
                            type="button"
                            onClick={() => {
                              setFieldValue("scan", "");
                              setMeditFiles([]);
                              handleNext();
                            }}
                          >
                            {t(IK.upload_later)}
                          </S.StyledButton>

                          <S.StyledButton
                            as="button"
                            $primary
                            type="button"
                            onClick={() => {
                              const isValid = handleImageRequired("scan", IK.scan_file_message, getFieldProps, setFieldError);
                              if (isValid || meditFiles.length > 0) {
                                handleNext();
                              }
                            }}
                          >
                            {t(IK.next)}
                          </S.StyledButton>
                        </S.ButtonLtBox>
                      </CardTail>
                    </>
                  )}
                </S2.CardInner>
              </S2.CardCol>
            </S2.CardRow>
          )}
        </FormikWizard>
      </Beforeunload>
    </>
  );
}

export default Register;
