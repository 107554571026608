import dayjs from "dayjs";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getLink, useGetStudyDesignList } from "../../../../../apis/patientApi";
import * as S2 from "../../../../../components/element/Button/style/ButtonLayout.style";
import { seraviweStatus } from "../../../../../json/studyStatusObject";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../style/Profile.style";

const ConfirmSeraview = ({ studyInfo, progress, lastDetail }) => {
  const { t } = useTranslation(["translation"]);
  const { patientId } = useParams();

  const { data } = useGetStudyDesignList(studyInfo.patientId, studyInfo?.studyId, studyInfo?.statusId);
  const studyDesignList = data?.data;

  /**세라뷰 이동하기 */
  const handleOnClick = function (type, designId) {
    getLink(patientId, type, studyInfo?.statusId, studyInfo?.studyId, designId)
      .then((response) => {
        if (response.data.code === 200) {
          window.open(response.data.data);
        }
      })
      .catch((error) => {
        Notiflix.Notify.failure(t(IK.again_message));
      });
  };

  const seraviewStatus = seraviweStatus(studyInfo, t);
  return (
    <>
      {progress ? (
        <S.ProfileHistoryDesc>
          <S.ProfileHistoryDescDot $complete={lastDetail >= "16"} />
          <dl>
            <dt>{seraviewStatus}</dt>
            {studyInfo?.createdDate && lastDetail >= "16" && (
              <dd>
                <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>
                {lastDetail === "16" &&
                  studyInfo?.reviseStudyDesignYn !== "Y" &&
                  studyDesignList?.map((item, index) => (
                    <S.ProfileHistoryDescBox key={index}>
                      <h4>
                        #{studyDesignList.length - index} {t(IK.design)} {item.isCenter === "Y" ? "(by Center)" : ""}
                      </h4>
                      <span>{dayjs(item?.createdDate).format("YYYY-MM-DD")}</span>
                      <S2.ButtonFlex>
                        <S2.StyledSmallButton
                          as="button"
                          onClick={(e) => {
                            handleOnClick("SERAVIEW", item.studyDesignId);
                          }}
                        >
                          {t(IK.seraview)}
                        </S2.StyledSmallButton>
                        <S2.StyledSmallButton
                          as="button"
                          onClick={(e) => {
                            handleOnClick("SERAVIEW_WEB", item.studyDesignId);
                          }}
                        >
                          {t(IK.seraview)} WEB
                        </S2.StyledSmallButton>
                      </S2.ButtonFlex>
                    </S.ProfileHistoryDescBox>
                  ))}
              </dd>
            )}
          </dl>
        </S.ProfileHistoryDesc>
      ) : (
        <>
          <S.ProfileHistoryCircle />
          <S.ProfileHistoryModalItem>
            <S.ProfileDepth1>
              <h3>{seraviewStatus}</h3>
              {studyInfo?.createdDate && <span>{dayjs(studyInfo?.createdDate).format("YYYY-MM-DD")}</span>}
            </S.ProfileDepth1>
          </S.ProfileHistoryModalItem>
        </>
      )}
    </>
  );
};

export default ConfirmSeraview;
